.techs {
  padding: 100px max(calc(50% - 570px), 70px);
  display: flex;
  flex-direction: column;
  background: var(--color-background-c, #272727);
}

.techs__title {
  margin: 0;
  padding: 90px 0 26px;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__subtitle {
  margin: 0;
  padding: 0 10px 100px;
  max-width: 480px;
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  justify-content: center;
  gap: 10px;
  list-style: none;
}

.techs__list-element {
  margin: 0;
  padding: 0;
  width: 90px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-d, #303030);
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  transition: box-shadow .2s linear;
}

.techs__list-element:hover {
  box-shadow: 5px 5px 5px #232323;
}

@media screen and (max-width: 768px) {
  .techs {
    padding: 90px 50px;
  }

  .techs__title {
    padding: 80px 0 22px;
  }

  .techs__subtitle {
    padding: 0 10px 83px;
    font-size: 12px;
    line-height: 18px;
  }

  .techs__list {
    grid-template-columns: repeat(auto-fit, 84px);
  }

  .techs__list-element {
    width: 84px;
    height: 57px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 420px) {
  .techs {
    padding: 70px 18px;
  }

  .techs__title {
    padding: 60px 0 24px;
    font-size: 30px;
    line-height: 36px;
  }

  .techs__subtitle {
    padding: 0 10px 50px;
    font-size: 11px;
    line-height: 16px;
  }

  .techs__list {
    padding: 0 10px;
  }
}