.logo {
  width: 38px;
  height: 38px;
  display: block;
  border-style: none;
  border-radius: 50%;
  background:
    var(--color-accent-a, #3DDC84)
    url('../../images/logo.svg')
    no-repeat
    center;
}