.movies-card {
  width: 100%;
  display: grid;
  grid-template-rows: 34px 34px 1fr;
  grid-template-columns: 1fr 58px;
  background: var(--color-background-b, #222);
  overflow: hidden;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, .08);
}

.movies-card__title {
  margin: 14px 0 auto 14px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.movies-card__duration {
  margin: auto 0 14px 14px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8B8B8B;
}

.movies-card__image-wrapper {
  padding-bottom: 56%;
  position: relative;
  width: 100%;
  grid-column: 1 / 3;
}

.movies-card__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8B8B8B;
  object-fit: cover;
}

.movies-card__button {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  align-self: center;
  justify-self: center;
  background: var(--color-background-d, #303030) url('../../images/save-icon.svg') no-repeat center;
  border: none;
  border-radius: 50%;
  grid-area: 1 / 2 / 3 / 3;
}

.movies-card__button_type_saved {
  background: var(--color-accent-a, #3DDC84) url('../../images/save-icon-saved.svg') no-repeat center;
}

.movies-card__button_type_delete {
  background: var(--color-background-d, #303030) url('../../images/close-button.svg') no-repeat center;
  background-size: 12px;
}