.form-input {
  margin: 10px 0 2px;
  padding: 0 15px;
  height: 46px;
  background: var(--color-background-d, #303030);
  color: var(--color-main, #fff);
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  outline: none;
  transition: color .2s linear;
}

.form-input_type_error {
  color: var(--color-accent-c, #EE3465);
  transition: color .2s linear;
}

.form-input:focus {
  border: 2px solid #8B8B8B;
  padding: 0 13px;
}

.form-input__label {
  min-height: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #8B8B8B;
}

.form-input__error {
  min-height: 25px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-accent-c, #EE3465);
  visibility: hidden;
  opacity: 0;
  transition: all .2s linear;
}

.form-input__error_visible {
  visibility: visible;
  opacity: 1;
  transition: all .2s linear;
}
