.about-me {
  padding: 110px max(calc(50% - 570px), 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-me__card {
  padding: 66px 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(220px, 600px);
  grid-template-rows: repeat(3, max-content);
  column-gap: min(calc(15% - 50px), 50px)
}

.about-me__photo {
  width: 270px;
  height: 327px;
  justify-self: end;
  grid-row: 1 / 5;
  grid-column: 2 / 3;
  border-radius: 10px;
}

.about-me__name {
  margin: 0;
  padding: 0 0 18px;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  letter-spacing: -0.04em;
}

.about-me__info {
  margin: 0;
  padding: 0 0 26px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.about-me__description {
  margin: 0;
  padding: 0 0 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.about-me__link {
  width: fit-content;
  align-self: end;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: var(--color-main, #fff);
}

@media screen and (max-width: 768px) {
  .about-me {
    padding: 90px 50px;
  }

  .about-me__photo {
    width: 255px;
    height: 307px;
  }

  .about-me__name {
    padding: 0 0 16px;
    font-size: 40px;
    line-height: 40px;
  }

  .about-me__info {
    padding: 0 0 20px;
    font-size: 12px;
    line-height: 18px;
  }

  .about-me__description {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .about-me__card {
    padding: 60px 0 0;
    width: fit-content;
    grid-template-columns: 292px;
  }

  .about-me__photo {
    margin: 0 0 40px;
    width: 292px;
    height: 352px;
    justify-self: center;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .about-me__name {
    padding: 0 0 20px;
    font-size: 30px;
    line-height: 36px;
  }

  .about-me__info {
    font-size: 11px;
    line-height: 16px;
  }

  .about-me__description {
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (max-width: 420px) {
  .about-me {
    padding: 70px 14px;
  }
}
