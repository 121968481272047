.navtab {
  margin: 0;
  padding: 0 0 40px;
  display: flex;
  gap: 10px;
  list-style: none;
}

.navtab__link {
  padding: 10px 17px;
  background: var(--color-background-d, #303030);
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-main, #fff);
  text-decoration: none;
}

@media screen and (max-width: 420px) {
  .navtab {
    padding: 0 0 22px;
    gap: 6px;
  }

  .navtab__link {
    padding: 5px 15px;
    border-radius: 6px;
    font-size: 10px;
  }
}