.register {
  padding: 56px max(calc(50% - 198px), 30px) 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.register__logo-wrapper {
  padding: 0 0 40px;
  display: flex;
}

.register__question {
  margin: 16px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #8B8B8B;
}

.register__link {
  color: var(--color-accent-b, #4285F4);
  text-decoration: none;
}

@media screen and (max-width: 420px) {
  .register__logo-wrapper {
    padding: 0 0 50px;
    justify-content: center;
  }

  .register__question {
    margin: 14px 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
