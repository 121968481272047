.page {
  --color-main: #fff;
  --color-background-a: #202020;
  --color-background-b: #222222;
  --color-background-c: #272727;
  --color-background-d: #303030;
  --color-accent-a: #3DDC84;
  --color-accent-b: #4285F4;
  --color-accent-c: #EE3465;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-main, #fff);
  background: var(--color-background-a, #202020);
  font-family: "Inter", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  transition: all 0.2s linear;
  border: none;
  outline: none;
}

.button:hover {
  opacity: 0.6;
  cursor: pointer;
}
