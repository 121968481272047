.not-found {
  padding: 30px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.not-found__title {
  margin: 154px 0 0;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
}

.not-found__subtitle {
  margin: 5px 0 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.not-found__back-button {
  margin: 184px 0 0;
  background: none;
  color: var(--color-accent-b, #4285F4);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border: none;
}

@media screen and (max-width: 420px) {
  .not-found__title {
    margin: auto 0 0;
    font-size: 80px;
    line-height: 97px;
  }

  .not-found__subtitle {
    margin: 10px 0 30px;
    font-size: 12px;
    line-height: 15px;
  }

  .not-found__back-button {
    margin: auto 0 0;
  }
}
