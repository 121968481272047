.saved-movies {
  padding: 0 max(calc(50% - 570px), 170px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saved-movies__text-empty {
  padding: 30px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--color-main, #fff);
  flex: 1;
}