.footer {
  padding: 80px 70px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer__about {
  margin: 0;
  padding: 0 0 20px;
  width: 100%;
  border-bottom: 1px solid #424242;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #8B8B8B;
}

.footer__copyright {
  margin: 0;
  padding: 20px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.footer__links {
  margin: 0;
  padding: 20px 0;
  display: flex;
  gap: 20px;
  list-style: none;
}

.footer__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-main, #fff);
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 80px 30px 0;
  }
}

@media screen and (max-width: 420px) {
  .footer {
    padding: 64px 14px 0;
    flex-direction: column;
  }

  .footer__about {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__copyright {
    padding: 10px 0;
    font-size: 12px;
    line-height: 16px;
    color: #8B8B8B;
    order: 1;
  }

  .footer__links {
    padding: 30px 0 20px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}