.profile {
  padding: 0 max(calc(50% - 205px), 30px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.profile_type_edit {
  justify-content: center;
}

.profile__greetings {
  margin: 0;
  padding: 30px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.profile__info {
  margin: 0;
  padding: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.profile__info-wrapper {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.profile__info-wrapper_type_bottom {
  align-items: flex-end;
  border-top: 1px solid #424242;
}

.profile__info-element {
  margin: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}

.profile__info-element_type_bold {
  font-weight: 500;
}

.profile__buttons {
  margin: 0;
  padding: 70px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
}

.profile__button {
  padding: 0;
  width: 100%;
  background: none;
  color: var(--color-main, #fff);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.profile__button_type_edit {
  margin: 16px 0 0;
}

.profile__button_type_colorful {
  color: var(--color-accent-c, #ee3465);
  font-weight: 500;
}
