.movies-card-list {
  margin: 0;
  padding: 0 0 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 24px;
  row-gap: 30px;
  list-style: none;
}

@media screen and (max-width: 768px) {
  .movies-card-list {
    column-gap: 30px;
    row-gap: 36px;
  }
}

@media screen and (max-width: 420px) {
  .movies-card-list {
    padding: 0 0 30px;
    row-gap: 16px;
  }
}