.filter-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.filter-checkbox__invisible-checkbox {
  display: none;
}

.filter-checkbox__visible-checkbox {
  height: 20px;
  width: 36px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 9999px;
  background-color: var(--color-background-a, #202020);
  box-shadow: 2px 2px 2px rgb(25 25 25 / 40%);
  transition: all .2s ease-in;
}

.filter-checkbox__visible-checkbox::after {
  height: 16px;
  width: 16px;
  content: '';
  position: absolute;
  left: 2px;
  border-radius: 9999px;
  background-color: #8B8B8B;
  transition: all .2s ease-in;
}

.filter-checkbox__invisible-checkbox:checked + .filter-checkbox__visible-checkbox::after {
  background-color: var(--color-accent-a, #3DDC84);
  left: 50%;
}
